import firebase from 'firebase/app'
import 'firebase/firestore'

// Your web app's Firebase configuration
var firebaseConfig = {
	apiKey: "AIzaSyBC8xamOnOFRDwLCNdpvvEwZYj9cwZSOx8",
	authDomain: "ksu-quest-e6c8b.firebaseapp.com",
	projectId: "ksu-quest-e6c8b",
	storageBucket: "ksu-quest-e6c8b.appspot.com",
	messagingSenderId: "658748508524",
	appId: "1:658748508524:web:ed59d0ee33fff51ecf4b49",
	measurementId: "G-YLHKER286L"
};
 // Initialize Firebase
 const firebaseApp = firebase.initializeApp(firebaseConfig);
 export default firebaseApp.firestore()